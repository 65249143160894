import React from "react";
import "./CarouselType.css";
import Carousel from "react-multi-carousel";
import TypeContainer from "./TypeContainer";
import typeDatabase from "../typeDatabase";
const CarouselType = () => {

    const enterType = (data)=>{
        return(
            <TypeContainer 
                key={data.id}
                image={data.image}
                type={data.type}
            />
        )
    }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div id="TypeDiv">
      <h1 id="Typeh1">Different types of products</h1>
      <Carousel
        autoPlay={true}
        autoPlaySpeed={1000}
        infinite={true}
        responsive={responsive}
        arrows={false}
      >
        {typeDatabase.map(enterType)}
      </Carousel>
    </div>
  );
};

export default CarouselType;
