import './App.css';
import React from 'react';
import AboutUs from './components/AboutUs';
import Products from './components/Products';
import ContactUs from './components/ContactUs';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import ProductDetail from './components/ProductDetail';
import CarouselspecificType from './components/CarouselspecificType';
function App() {
  return (
    <React.Fragment>
    <header>
    
      <Navbar />
    </header>
    <main>
      <Routes>
        {/* <Card/> */}
        <Route path='/aboutus' element={<AboutUs/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/products/:productName' element={<ProductDetail/>}/>
        <Route path='/:producttype' element={<CarouselspecificType/>}/>  //Over here :this name should be same as useParams object data name 
        <Route path='/contactus' element={<ContactUs/>}/>
        <Route path='/' element={<Home/>}/>
      </Routes>
      <Footer/>
    </main>
    </React.Fragment>
  );
}

export default App;