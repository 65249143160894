import React from "react";
import "./Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {motion} from "framer-motion"
const Footer = () => {
  return (
    <footer>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        whileInView={{ opacity: 1 }}
      >
        <div className="Footerdiv" id="Contact">
          <p>Follow Us On:</p>
          {/* <FacebookIcon></FacebookIcon> */}
          <a href="/">
            <InstagramIcon />
          </a>
          <a href="/">
            <FacebookIcon />
          </a>
        </div>
        <span className="footerSpan">
          © 2024 N. U. Engineering Works LTD. All rights reserved
        </span>
      </motion.div>
    </footer>
  );
};

export default Footer;
