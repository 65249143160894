import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contactus.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import { motion } from "framer-motion";

const ContactUs = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    // emailjs.sendForm("service_4398nyu", "template_eclyr4g", form.current, "t1SoN0tPvgZm7sHgc")
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contactContainer">
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        whileInView={{ opacity: 1 }}
      >
        <h4>Write to us</h4> {/*Inquiry here*/}
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Mobile Number</label>
          <input type="tel" id="mobile" name="user_number" />
          <label>Message</label>
          <textarea id="message" name="message" />
          <input className="btn" type="submit" value="Send" />
        </form>
        <h4>Reach Us</h4>
        <div className="detailsDiv">
          <div className="tableDiv">
            <table className="contactTable">
              <tr>
                <td>
                  <LocationOnIcon className="location" />
                </td>
                <td>
                  <tr>
                    N. U. ENGINEERING WORKS
                    {/* <h5>N. U. Engineering Works</h5> */}
                  </tr>
                  <tr>
                    Shree Rang Nagar, Kailash Road, Behind Patel Chamber,
                    Valsad-396001, Gujarat, India
                  </tr>
                </td>
              </tr>
              <tr>
                <td>
                  <PersonIcon />
                </td>
                <td>Dinesh N. Lad (Proprietor)</td>
              </tr>
              <tr>
                <td>
                  <EmailIcon />
                </td>
                <td>nuengineering1995@yahoo.com </td>
              </tr>
              <tr>
                <td>
                  <CallIcon />
                </td>
                <td>Call 9375814513</td>
              </tr>
            </table>
          </div>
          <div className="gmapDiv">
            <iframe
              title="nu"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3734.256453449251!2d72.93610107443627!3d20.618401080930244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0e92dff731821%3A0xb231cc8b90aa57e0!2sRang%20nagar!5e0!3m2!1sen!2sin!4v1685096409091!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactUs;
