import React from 'react'
// import CarouselClient from './CarouselClient';
import CarouselContainer from './CarouselContainer';
const Products = () => {
  return (
    <div>
      <CarouselContainer/>
    </div>
  )
}

export default Products