
// import React from "react";
// import "bootstrap/dist/css/bootstrap.css";
// import nuSymbolLogo from "./images/nu_symbol1.png";
// import typeDatabase from "../typeDatabase";
// import "./Navbar.css";
// import { NavLink, Link } from "react-router-dom";

// function Navbar() {
//   return (
//     <nav id="navbarSticky" className="navbar flex justify-between items-center">
//       <div className="navbar-logo md:flex md:justify-center">
//         <NavLink to="/">
//           <img src={nuSymbolLogo} alt="image1" />
//         </NavLink>
//       </div>
//       <ul className="navbar-links">
//         <li>
//           <NavLink 
//           to="/"
//           className={({isActive})=>
//             isActive ? "active" : undefined
//           }
//           end
//           >Home</NavLink>
//         </li>
//         <li>
//           <NavLink 
//           to="/aboutus"
//           className={({isActive})=>
//             isActive ? "active" : undefined
//           }
//           >About Us</NavLink>
//         </li>
//         <li>
//           <div className="dropdown">
//             <NavLink 
//             to="/products"
//             className={({isActive})=>
//             isActive ? "active" : undefined
//           }
//             >Products</NavLink>
//             <div className="dropdown-content">
//               {typeDatabase.map((data) => (
//                 <Link key={data.type} to={`/${data.type}`}>
//                   {data.type}
//                 </Link>
//               ))}
//             </div>
//           </div>
//         </li>
//         <li>
//           <NavLink
//           className={({isActive})=>
//             isActive ? "active" : undefined
//           } 
//           to="/contactus"
//           >Contact Us</NavLink>
//         </li>
//       </ul>
//     </nav>
//   );
// }

// export default Navbar;


import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import nuSymbolLogo from "./images/nu_symbol1.png";
import typeDatabase from "../typeDatabase";
import "./Navbar.css";
import { NavLink, Link } from "react-router-dom";

function Navbar() {
  return (
    <nav id="navbarSticky" className="navbar flex justify-between items-center">
      <div className=" md:flex md:justify-center navbar-logo">
        <NavLink to="/">
          <img src={nuSymbolLogo} alt="image1" className="h-12 md:h-auto" />
        </NavLink>
      </div>
      <ul className="navbar-links flex space-x-4">
        <li>
          <NavLink 
            to="/"
            className={({isActive}) => isActive ? "active" : undefined}
            end
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/aboutus"
            className={({isActive}) => isActive ? "active" : undefined}
          >
            About Us
          </NavLink>
        </li>
        <li className="relative">
          <div className="dropdown">
            <NavLink 
              to="/products"
              className={({isActive}) => isActive ? "active" : undefined}
            >
              Products
            </NavLink>
            <div className="dropdown-content absolute hidden md:block bg-white shadow-lg rounded-lg mt-2">
              {typeDatabase.map((data) => (
                <Link key={data.type} to={`/${data.type}`} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                  {data.type}
                </Link>
              ))}
            </div>
          </div>
        </li>
        <li>
          <NavLink 
            className={({isActive}) => isActive ? "active" : undefined} 
            to="/contactus"
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
