import t1 from "./components/images/t1.jpg"

import hgcm from "./components/images/giltter cutting machine.jpeg"
import tp from "./components/images/TIMING PULLEY.png"
import gmain from "./components/images/gmain.png"
import twistscrew from "./components/images/twistscrew.jpg"
import bodymixture from "./components/images/bodymixture.jpg"

const typeDatabase = [
    {
        id:1,
        type:"Textile",
        image:t1
    },
    {
        id:2,
        type:"Cutting machine",
        image:hgcm
    },
    {
        id:3,
        type:"Timing Pulley",
        image:tp
    },
    {
        id:4,
        type:"Gear",
        image:gmain
    },
    {
        id:5,
        type:"Conveying screw",
        image:twistscrew
    },
    {
        id:6,
        type:"Textile Components",
        image:bodymixture
    },
]

export default typeDatabase;