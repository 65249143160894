import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CarouselContainer.css";
import Card from "./Card";
import productDatabase from "../productDatabase";
import typeDatabase from "../typeDatabase";

const enterData = (data) => {
  return (
    <Card
      key={data.id}
      idd={data.id}
      namee={data.name}
      price={data.price}
      descript={data.description}
      imageURL={data.image}
    />
  );
};

const CarouselContainer = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Group products by type
  const groupedProducts = typeDatabase.map((category) => {
    const products = productDatabase.filter((product) => product.type === category.type);
    return {
      ...category,
      products: products,
    };
  });

  return (
    <>
      <div id="productDiv">
        {groupedProducts.map((group) => (
          <div key={group.id}>
            <h1 id="CCh1id">{group.type} Products</h1>
            <Carousel autoPlay={true} autoPlaySpeed={1400} showDots={true} responsive={responsive}>
              {group.products.map(enterData)}
            </Carousel>
          </div>
        ))}
      </div>
    </>
  );
};

export default CarouselContainer;
