import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import productDatabase from '../productDatabase';
import Card from './Card';
import "./CarouselspecificType.css";
const CarouselspecificType = () => {

    const [typeArray,settypeArray] = useState([]);
    const enterData = (data)=>{
        return(
          <Card
            key={data.id}
            idd={data.id}
            namee={data.name}
            price={data.price}
            descript = {data.description}
            imageURL = {data.image}
          />
        )
      }

    let {producttype} = useParams();
    console.log(producttype);

    useEffect(()=>{
        const specficTypeProduct = productDatabase.filter((data)=>{
            return producttype === data.type;
        });
        settypeArray(specficTypeProduct);
    },[producttype])
    console.log(typeArray);
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 1024 },
          items: 4,
          slidesToSlide: 1,
        },
        desktop: {
          breakpoint: { max: 1024, min: 800 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 800, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      };
  return (
    <>
        <div id="specificTypeDiv">
        <h1 id="Csth1">{producttype}</h1>
        <Carousel
          autoPlay={true}
          autoPlaySpeed={1000}
          showDots={true}
          responsive={responsive}>
          {typeArray.map(enterData)}
        </Carousel>
      </div>
    </>
  )
}

export default CarouselspecificType