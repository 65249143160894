import React from "react";
import "./Clientcard.css";
import nuSymbolLogo from './images/nu_symbol1.png';
const Clientcard = (props) => {
  return (
    <div>
      <div className="Clcard">
        <img className="Clproduct--image" src={props.clientLogo} alt="Image2"></img>
      </div>
    </div>
  );
};

export default Clientcard;
