import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";

const Card = (props) => {
  
  return (
    <div className="cardContainer">
      <div className="card">
        <img className="product--image" src={props.imageURL} alt="Image1"></img>
        <h5 className="product--name">{props.namee}</h5>
        <p>
        <Link to={`/products/${props.namee}`}><button>Show more details</button></Link>
        </p>
      </div>
   </div>
  );
};

export default Card;
