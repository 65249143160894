import React from "react";
import "./Home.css";
import homeBackground from "./images/homebackground.jpg";
// import Clientcard from './Clientcard';
import CarouselClient from "./CarouselClient";
import CarouselType from "./CarouselType";
import { motion } from "framer-motion";
import Typewriter from 'typewriter-effect';

const Home = () => {
  return (
    <div id="homeContainer">
      {/* Below is my heroimage!!!!!!! */}
      <div className="hero-image-container">
        <img id="image" src={homeBackground} alt="img" />
        {/* Content, CTA, and Headline */}
        <div className="text-overlay">
          <div className="content-container">
          {/* <Typewriter
            options={{
              strings: ['Hello', 'World'],
              autoStart: true,
              loop: true,
            }}
          /> */}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.8 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <h1 className="company-name">NU Engineering Works</h1>
              <p className="cta-text">
              <Typewriter
                options={{
                  strings: ['Explore our wide range of mechanical parts.', 'Find the perfect solutions according to your needs.'],
                  autoStart: true,
                  loop: true,
                  delay: 30,
                  // deleteSpeed: 50
                  // changeDelay:()=>{}
                }}
              />
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      <CarouselType />
      <CarouselClient />
    </div>
  );
};

export default Home;
