
import React from "react";
import "./TypeContainer.css";
import { Link } from "react-router-dom";

const TypeContainer = (props) => {
  const typeBtn = () => {
    console.log("Clicked");
  };
  
  return (
    <div>
      <Link to={`/${props.type}`} className="no-underline text-inherit">
        <div className="shadow-md w-64 h-42 mb-4 m-5 text-center">
          <img className="w-full h-24 object-contain" src={props.image} alt="Image2" />
          <h4 className="m-2 p-1">{props.type}</h4>
        </div>
      </Link>
    </div>
  );
};

export default TypeContainer;
