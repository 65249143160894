import t1 from "./components/images/t1.jpg"
import t2 from "./components/images/t2.jpg"
import t3 from "./components/images/t3.jpg"
import t4 from "./components/images/t4.jpg"
import t5 from "./components/images/t5.jpg"
import t6 from "./components/images/t6.jpg"
import t7 from "./components/images/t7.jpg"
import t8 from "./components/images/t8.jpg"
import t9 from "./components/images/t9.jpg"
import t10 from "./components/images/t10.jpg"
import t11 from "./components/images/t11.jpg"
import t12 from "./components/images/t12.jpg"
import glittermachine from "./components/images/glittermachine.png";
import yarnmachine from "./components/images/yarnmachine.png";
import timingpulley from "./components/images/TIMING PULLEY.png";
import xlpulley from "./components/images/xl pulley.jpg";
import s8mpulley from "./components/images/S8M PULLEY.jpg";
import pulley14m from "./components/images/14M PULLEY.jpg";
import spurgear from "./components/images/SPUR GEAR.jpg";
import helicalgear from "./components/images/Helical_gears.jpg";
import bevelgear from "./components/images/BEVEL GEAR.png";
import spiralbevelgear from "./components/images/SpiralBevelGear.jpg";
import gearrack from "./components/images/Gear_rack.jpg";
import differentialgear from "./components/images/differentialGear.jpg";
import wormwheel from "./components/images/worm-wheel.jpg";
import planetarygearboxes from "./components/images/planetarygear.jpg";
import screwgears from "./components/images/screw_gears.jpg";
import twistscrew from "./components/images/twistscrew.jpg";
import microsetting from "./components/images/microsetting.jpg";
import slwage from "./components/images/slwage.jpg";
import m8timing from "./components/images/S8M PULLEY.jpg";


const productDatabase = [
  {
    id:1,
    name: 'Looms Machine Eccentric Part',
    description: "Consistent innovation, coupled with an alert understanding of customer's needs & demands, makes us the most renowned firm of Looms Machine Eccentric Part.",
    features: ["Optimum strength", "Anti-corrosive", "Easy to use"],
    image: t1,
    type:"Textile"
  },
  {
    id:2,
    name: 'Textile Machine Co Mat Nut',
    description: "We have a team of self motivated and proficient professionals who help us to provide the best quality range of Textile Machine Co Mat Nut.",
    features: ["Robust Design", "Dimensional Accuracy", "Optimum Strength"],
    image: t2,
    type:"Textile"
    
  },
  {
    id:3,
    name: 'Textile Machine Holder',
    description: "It is available by us in distinctive designs & sizes; also we manufacture, supply, trade and export its ranges in mass varieties. To assure hassle free operations we have maintained a modern production unit that has all the facilities available required for the flawless production of textile parts. Many big brands of textile industry demand for these products to ensure the accuracy of production. With all these facilities we have garnered a large client base all across the globe.",
    features:["Chemical-attack resistant", "Fabric take-away system", "Support low power consumption"],
    image: t3,
    type:"Textile"
    
  },
  {
    id:4,
    name: 'Looms Machine Lever',
    description: "Many big brands of textile industry demand for these products to ensure the accuracy of production. With all these facilities we have garnered a large client base all across the globe.",
    features: ["Chemical-attack resistant", "Fabric take-away system", "Support low power consumption"],
    image: t4,
    type:"Textile"
    
  },
  {
    id:5,
    name: 'Belt Coupling',
    description: "It is used in ring frame machine in textile indutry",
    features:[],
    image: t5,
    type:"Textile"
    
  },
  {
    id:6,
    name: 'Brack liner',
    description: "It is used in comber machine in textile industry",
    features:[],
    image: t6,
    type:"Textile"
    
  },
  {
    id:7,
    name: 'Industrial Chain Sprocket',
    description: "We have a wide range of chain sprocket part  like single chain sprocket, duplex chain sprocket & triplex chain sprocket. We developed size of 3/8 ,1/2 ,5/8 & 3/4.",
    features:[],
    image: t7,
    type:"Textile"
    
  },
  {
    id:8,
    name: 'Clutch Coupling',
    description: "It used in PFO machine and Other Machine which couple with motor and drive end shaft of any components.",
    features:[],
    image: t8,
    type:"Textile"
    
  },
  {
    id:9,
    name: 'Eccentric Shaft',
    description: "We are the prominent manufacturer and exporter of Turned & Machined Shafts for any type of machine with customer specification with appropriate heat treatment and surface treatment like hardening, hard chrome plating, grinding, etc.",
    features:[],
    image: t9,
    type:"Textile"
    
  },
  {
    id:10,
    name: 'Swivel Bracket',
    description: "It is used in ring frame machine in Textile industry",
    features:[],
    image: t10,
    type:"Textile"
    
  },
  {
    id:11,
    name: 'Worm Wheel gear',
    description: "We provide a wide collection of Worm Wheel Gears that are extensively used to reduce rotational speed. Our range of worm wheel gear is largely used to serve the various Industrial application. We manufacture worm wheels from advanced technology using the fine raw material.Worm gears have a screw pinion which turns along a spur gear.Motion can be transmitted between shafts that are right angles. The worm gear in this application is called the pinion the worm wheel is called the ring.",
    features:["High tensile strength","Resistant to corrosion","Specific dimensions"],
    image: t11,
    type:"Textile"
    
  },
  {
    id:12,
    name: 'Worm Shaft',
    description: "We offer clients a wide range of Worm Shaft which is manufactured as per clients requirements. A Worm Shaft or a worm gear as it often referred to is a long cylindrical type of gear that provides a surface for all bearings to ride on.",
    features:["High tensile strength","Load bearing capacity","Resistant to corrosion","Specific dimensions"],
    image: t12,
    type:"Textile"
    
  },
  {
    id:13,
    name: 'Hexagonal glitter cutting machine',
    description: "The Hexagonal Glitter Cutting Machine is combined with a user friendly control panel and provides continuous Hexagon shaped particles. The Glitter Cutting Machine is capable of cutting polyester film up to 50Micron thick to produce hexagonal particles.",
    features:['Robust machine','Simple to operate and maintain.','Film is fed into the knife set run synchronously by two variable feed drives.','Bed Knives and Rotary Knives are made to strict tolerances', 'Long life Timing Belts.','Less vibration of machine due to rigid body.'],
    image: glittermachine,
    type:"Cutting machine"
    
  },
  {
    id:14,
    name: 'Yarn Cutting Attachment',
    description: "The Yarn Cutting attachment is mounted on a glitter cutting machine replace by the upper assembly to produced the straight yarn in 50micron or up to 25mm With PLC base Program.",
    features:['Robust machine','Simple to operate and maintain.','Less vibration of machine due to rigid body.'],
    image: yarnmachine,
    type:"Cutting machine"
    
  },
  {
    id:15,
    name: 'Timing pulley',
    description: "This timing pulley can be used in combination with a timing belt. There are two types of pulleys: pulleys that are stocked with the shaft holes pre-bored and custom-made products that are produced on a one-of-a-kind basis.Timing pulley are use in different industries like Textile Machineries, Pulp & Paper mill Machineries, Pharmaceutical Machineries, Printing Machineries, Packaging Machineries, Rolling Mill Machineries, Graphite Machineries, Food Processing Machineries, Material Handling Pulleys, Bakery Machineries, Plastic Machineries, General Machineries, Mechanical Machineries, Hydraulic Machineries, Automobile Industries.Material MOC :  Alloy steel & Aluminium Timing Pulley Type :- XL , T-10,S8m, 8m,14m,We are developing customize size of Timing pulley as per customer requirement.",
    features:["Easy and Convenient to use","Sturdiness","High strength"],
    image: timingpulley,
    type:"Timing Pulley"
    
  },
  {
    id:16,
    name: 'XL Type Timing Pulley',
    description: "We are developing customize size of Timing pulley as per customer requirement.",
    features:[],
    image: xlpulley,
    type:"Timing Pulley"
    
  },
  {
    id:17,
    name: 'T-10 Type Timing Pulley',
    description: "We are developing customize size of Timing pulley as per customer requirement",
    features:[],
    image: s8mpulley,
    type:"Timing Pulley"
    
  },
  {
    id:18,
    name: '8M Timing Pulley',
    description: "We are developing customize size of Timing pulley as per customer requirement",
    features:[],
    image: m8timing,
    type:"Timing Pulley"
    
  },
  {
    id:19,
    name: '14M Timing Pulley',
    description: "We offer clients a wide range of Worm Shaft which is manufactured as per clients requirements. A Worm Shaft or a worm gear as it often referred to is a long cylindrical type of gear that provides a surface for all bearings to ride on.",
    features:[],
    image: pulley14m,
    type:"Timing Pulley"
  },
  {
    id:20,
    name: 'Spur Gear',
    description: "Spur gears belong to the parallel shaft gear group and are cylindrical gears with a tooth line which is straight and parallel to the shaft.We are developing customize size of various spur gear as per customer requirement.",
    features:["Spur gears are the most widely used gears that can achieve high accuracy with relatively easy production processes."],
    image: spurgear,
    type:"Gear"
  },
  {
    id:21,
    name: 'Helical Gear',
    description: "We are developing customize size of various Helical gear as per customer requirement.",
    features:["They have better teeth meshing than spur gears and have superior quietness and can transmit higher loads, making them suitable for high speed applications","When using helical gears, they create thrust force in the axial direction, necessitating the use of thrust bearings.","Helical gears come with right hand and left hand twist requiring opposite hand gears for a meshing pair."],
    image: helicalgear,
    type:"Gear"
  },
  {
    id:22,
    name: 'Bevel Gear',
    description: "A bevel gear has a cone as its pitch surface and its teeth are cut along the cone.We are developing customize size of various Bevel gear as per customer requirement.",
    features:["Kinds of bevel gears include straight bevel gears, helical bevel gears, spiral bevel gears, miter gears, angular bevel gears, crown gears, zerol bevel gears and hypoid gears."],
    image: bevelgear,
    type:"Gear"
  },
  {
    id:23,
    name: 'Spiral Bevel Gear',
    description: "Spiral bevel gears are bevel gears with curved tooth lines.We are developing customize size of various spiral bevel gear as per customer requirement",
    features:["Due to higher tooth contact ratio, they are superior to straight bevel gears in efficiency, strength, vibration and noise.",". On the other hand, they are more difficult to produce. Also, because the teeth are curved."],
    image: spiralbevelgear,
    type:"Gear"
  },
  {
    id:24,
    name: 'Gear rack',
    description: "We are developing customize size of various  gear rack as per customer requirement.",
    features:["Gear racks can be broadly divided into straight tooth racks and helical tooth racks, but both have straight tooth lines.","By machining the ends of gear racks, it is possible to connect gear racks end to end."],
    image: gearrack,
    type:"Gear"
  },
  {
    id:25,
    name: 'Differential gear',
    description: "The product has various industrial applications for providing high torque and smooth speed reduction. These gearboxes are likewise manufactured keeping specific specifications, which will likewise fluctuate contingent upon the application This gear box mainly used for COMBER E62 Machine.",
    features:["Robust Design","Dimensional Accuracy","Optimum Strength"],
    image: differentialgear,
    type:"Gear"
  },
  {
    id:26,
    name: 'Worm wheel & Worm shaft',
    description: "A worm gear is a thread cut into a round bar, and a worm wheel is a gear that meshes with the worm at a shaft angle of 90 degrees. The set of a worm and worm wheel is called a worm gear.We are developing customize size of various  Worm shaft  as per customer requirement.",
    features:[],
    image: wormwheel,
    type:"Gear"
  },
  {
    id:27,
    name: 'Planetary Gearboxes',
    description: "Planetary Gearboxes are a type of gearbox where the input and output both have the same centre of rotation. This means that the centre of the input gear revolves around the centre of the output gear and the input and output shafts are aligned.We are developing customize size of various  Planetary Gearbox  as per customer requirement.",
    features:[],
    image: planetarygearboxes,
    type:"Gear"
  },
  {
    id:28,
    name: 'Screw gears',
    description: "Screw gears are a pair of same hand helical gears with the twist angle of 45° on non-parallel, non-intersecting shafts. Because the tooth contact is a point, their load carrying capacity is low and they are not suitable for large power transmission.We are developing customize size of various  Screw Gear  as per customer requirement.",
    features:[],
    image: screwgears,
    type:"Gear"
  },
  {
    id:29,
    name: 'Twist screws',
    description: "The conveyor screw is an essential part of screw conveyors. It provides motion to the material while revolving inside the trough. It can be helicoids, sectional, or any other unique design as per the requirements of our work",
    features:[],
    image: twistscrew,
    type:"Conveying screw"
  },
  {
    id:30,
    name: 'Microsetting attachment',
    description: "",
    features:[],
    image: microsetting,
    type:"Textile Components"
  },
  {
    id:31,
    name: 'Selvedge unit',
    description: "",
    features:[],
    image: slwage,
    type:"Textile Components"
  },

];

export default productDatabase;
