import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import productDatabase from "../productDatabase";
import { Col, Row } from "react-bootstrap";
import "./ProductDetail.css";
const ProductDetail = () => {
  const [data, setData] = useState({});
  let { productName } = useParams();
  console.log(productName);
                    
  useEffect(() => {
    const filteredProduct = productDatabase.find((product) => {
      return product.name === productName;
    });
    setData(filteredProduct);
  }, [productName]);
  console.log(data);

  // const BuyFunction = async (event) => {};
  return (
    <div>
      <Row className="item">
        <Col sm={6}>
          <div className="divi">
            <img src={data.image} alt="Image2"></img>
          </div>
        </Col>
        <Col className="info">
          <h2>{data.name}</h2>
          <br />
          <br />
          <p>{data.description}</p>
          {data.features && data.features.length>0 && <h4>Features:</h4>}
          {data.features &&
            data.features.length > 0 &&
            data.features.map((entry) => {
              return (
                <ul>
                  <li>{entry}</li>
                </ul>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};
export default ProductDetail;
