import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Clientcard from "./Clientcard";
import nuSymbolLogo from './images/nu_symbol1.png';
import yamuna from './images/yamuna.jpg';
import welspun from "./images/welspunlogo.png";
import qualitygears from "./images/qualitygears.jpeg";
import greatwhite from "./images/greatwhite.jpg";
import welspunflooring from "./images/welspun-flooring.jpg";

import "./CarouselClient.css";
const CarouselClient = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div id="clientDiv">
      <h1 id="Cclienth1">Our clients</h1>
        <Carousel
          autoPlay={true}
          autoPlaySpeed={1000}
          infinite={true}
          responsive={responsive}
          arrows={false}
        >
          <Clientcard 
            clientLogo = {welspun}
          />
          <Clientcard 
            clientLogo = {welspunflooring}
          />
          <Clientcard 
            clientLogo = {yamuna}
          />
          <Clientcard 
            clientLogo = {qualitygears}
          />
          <Clientcard 
            clientLogo = {greatwhite}
          />
        </Carousel>
      </div>
    </>
  );
};

export default CarouselClient;
