import React from "react";
import "./Aboutus.css";
const AboutUs = () => {
  return (
    <div className="aboutContainer">
      <div className="image">
        <img
          src="https://media.istockphoto.com/id/538785322/photo/gears-on-a-project.jpg?s=612x612&w=0&k=20&c=MhZpU5s-g_vPFS_mBELfKV53kJ7HGmA8U8vAjjVTqg8="
          alt="Imagesss"
        ></img>
      </div>
      <div className="script">
        <h1>About Us</h1>
        <p2>
          <b>"N U ENGINEERING WORKS”</b> is an famous company that is betrothed
          in manufacturing a wide range of Machinery parts , Textile Machine
          Parts, Timing Pulley, Differential Gear, Spiral Bevel Gear and
          Conveying Screw, Hexagonal Glitter Cutting Machine & Strip Cutting
          Machine. Since our origin in the year 1995, Our Company that is
          incorporated with an objective of providing high quality range of
          products across the nation. Our Company is located at Valsad (Gujarat,
          India), we are supported by a well functional infrastructural unit and
          devoted professionals who play a crucial role in the expansion of our
          firm. Our Company mainly focus on taking a challenging component and
          successfully develop that component under the guides of our company
          owner “Mr Dinesh N Lad” ,we have gained a remarkable position in the
          market. Since last 28 years our company serves the well-known MNC
          companies and we have successfully developed their critical
          components.
        </p2>
      </div>

      <div id="thirdDiv">
        <h2>Why Us?</h2>
        <p>
          With the support of our veteran and capable team members, we are
          betrothed in providing qualitative range of products that we provide
          in diverse specifications and in large quantity. Due to the following
          factors, we are able to achieve the belief of our valued clients:
        </p>
        <ul>
          <li>Robust infrastructural base</li>
          <li>Competent team of professionals</li>
          <li>Stringent quality standards</li>
          <li>Client-oriented approach</li>
          <li>Customization packaging facility</li>
          <li>Realistic price structure</li>
          <li>Prompt delivery</li>
          <li>Strong logistic support</li>
        </ul>
      </div>

      <div id="fourthDiv">
        <h3>
          <b>Factsheet</b>
        </h3>
        <h5>Basic Information</h5>
        <table className="Abouttable">
          <tr>
            <td>Nature of Business</td>
            <td>Manufacturer</td>
          </tr>
          <tr>
            <td>Company Proprietor</td>
            <td>Dineshbhai Narottamdas Lad</td>
          </tr>
          <tr>
            <td>Total Number of Employees</td>
            <td>Upto 10 People</td>
          </tr>
          <tr>
            <td>Year of Establishment</td>
            <td>1995</td>
          </tr>
          <tr>
            <td>Legal Status of Firm</td>
            <td>Individual - Proprietor</td>
          </tr>
          <tr>
            <td>Annual Turnover</td>
            <td>Rs. 75 lakhs - 1 Crore</td>
          </tr>
        </table>

        <h5>Statutory Profile</h5>
        <table className="Abouttable">
          <tr>
            <td>Banker</td>
            <td>Induslnd Bank</td>
          </tr>
          <tr>
            <td>GST No.</td>
            <td>24AAGPL4761M1ZF</td>
          </tr>
        </table>

        <h5>Packaging/Payment and Shipment Details</h5>
        <table className="Abouttable">
          <tr>
            <td>Payment Mode:</td>
            <td>Credit Card/Cash/Cheque/DD</td>
          </tr>
          <tr>
            <td>Shipment Mode</td>
            <td>By road</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default AboutUs;
